
export function getUrlParam(sParam) {
  if (typeof window === 'object') {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam)
      {
        return sParameterName[1];
      }
    }
  }
}

export function createCookie(name, value, days) {
  let expires;
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toGMTString();
  }
  else {
    expires = '';
  }
  document.cookie = name + "=" + value + expires + ";path=/;SameSite=Lax";
}

export function eraseCookie(name) {
  createCookie(name, '', -1);
}

export async function injectScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.addEventListener('load', resolve);
    script.addEventListener('error', () => reject('Error loading script.'));
    script.addEventListener('abort', () => reject('Script loading aborted.'));
    document.head.appendChild(script);
  });
} 

export function getParameterByName(name, url) {
  console.log(name);
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  if (name === 'utm_content' || name === 'utm_medium' || name === 'utm_source' || name === 'utm_campaign') {
    return decodeURIComponent(results[2].replace(/\+/g, " ").replace(/_/g, ""));
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function readCookie(name) {
  if (typeof (document) !== 'undefined') {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
}