import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  border: 1px solid #000;
  cursor: pointer;
  margin: 0 2.5rem;
  min-height: 5rem;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  &.show {
    border: 1px solid #638EFF;
  }
  .title-wrapper {
    align-items: center;
    display: flex;
    min-height: 5rem;
    justify-content: center;
    flex-direction: row;
    outline: none;
    h4, p {
      font-size: 1.4rem;
      font-weight: 600;
    }
    h4 {
      margin: 0;
    }
    .flair {
      margin: 0 1rem 0 2rem;
      font-size: 2rem;
      width: 2rem;
    }
    p {
      color: #638EFF;
      margin: 0 0 0 4.5rem;
    }
  }
  .info {
    display: none;
    font-size: 1.2rem;
    padding: 0 0 2rem 1.5rem;
    line-height: 2.5rem;
    max-width: 28rem;
    &.show {
      display: block;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 30px;
    margin: 1rem;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #2196F3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      left: 0;
      transform: translateX(32px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
`;