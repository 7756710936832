import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: #F2F2F2;
  color: #000;
  height: 100vh;
  left: 50%;
  overflow-y: scroll;
  pointer-events: initial;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 40rem;
  @media only screen and (max-width: 750px) {
    height: 100%;
    max-width: 40rem;
    width: auto;
  }
  &.hide {
    display: none;
  }
  .close-btn {
    align-items: center;
    background: #E3E3E3;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    margin: 1rem;
    padding: .5rem 0;
    position: absolute;
    right: 0;
    width: 7%;
    &:hover {
      filter: brightness(1.2);
    }
    @media only screen and (max-width: 750px) {
      margin: 1rem 2rem;
    }
    span {
      background: #2D2D2D;
      padding: 0 0.5rem;
      p {
        position: relative;
        top: -2px;
      }
    }
  }
  .cookie-text {
    margin-top: 3.8rem;
    b {
      display: block;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }
  h4, p, button, a {
    color: #000;
    font-family: 'roboto', sans-serif;
    max-width: 35rem;
    width: calc(100% - 3rem);
  }
  h4 {
    font-weight: 600;
    font-size: 1.8rem;
    margin: 4rem 2rem 2rem;
    font-family: roboto, sans-serif;
    letter-spacing: initial;
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
  }
  p {
    margin: 0 2rem;
    line-height: 1.3;
    font-size: 1.6rem;
    font-weight: 300;
  }
  a {
    text-decoration: underline;
  }
  button {
    background: #477AFD;
    color: #fff;
    cursor: pointer;
    font-size: 1.8rem;
    height: 5rem;
    left: 50%;
    transform: translateX(-50%);
    margin: 20px auto;
    position: relative;
    border: none;
    transition: 0.25s ease-in-out;
    &:hover {
      filter: brightness(1.2);
    }
    @media only screen and (max-width: 750px) {
      margin: 2.5rem 0;
      max-width: 30rem;
    }
  }
`;