exports.components = {
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-creatorcup-js": () => import("./../../../src/pages/creatorcup.js" /* webpackChunkName: "component---src-pages-creatorcup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-league-js": () => import("./../../../src/pages/league.js" /* webpackChunkName: "component---src-pages-league-js" */),
  "component---src-pages-league-scc-js": () => import("./../../../src/pages/league-scc.js" /* webpackChunkName: "component---src-pages-league-scc-js" */),
  "component---src-pages-matches-js": () => import("./../../../src/pages/matches.js" /* webpackChunkName: "component---src-pages-matches-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-players-js": () => import("./../../../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-scores-js": () => import("./../../../src/pages/scores.js" /* webpackChunkName: "component---src-pages-scores-js" */),
  "component---src-pages-standings-js": () => import("./../../../src/pages/standings.js" /* webpackChunkName: "component---src-pages-standings-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-vegas-js": () => import("./../../../src/pages/vegas.js" /* webpackChunkName: "component---src-pages-vegas-js" */),
  "component---src-pages-vods-js": () => import("./../../../src/pages/vods.js" /* webpackChunkName: "component---src-pages-vods-js" */)
}

