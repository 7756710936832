import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  bottom: 0;
  color: #000000;
  height: 100vh;
  position: fixed;
  pointer-events: none;
  width: 100%;
  z-index: 1000;
  &.is-hidden {
    display: none;
  }
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    padding: 0;
  }
  .hide-manage-popup {
      display: none;
      width: 100%;
      height: calc(100vh);
      position: relative;
      top: 5rem;
      &.opacity {
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        display: block;
        overflow: hidden;
        top: 0;
      }
  }
  .wrapper {
      background: #F2F2F2;
      bottom: 0;
      display: flex;
      flex-direction: column;
      height: 40rem;
      padding: 2rem;
      pointer-events: initial;
      position: fixed;
      max-width: 40rem;
      width: 100%;
      &.hide {
        display: none;
      }
      @media only screen and (max-width: 1200px) {
        height: auto;
        width: calc(100vh * (400/2560));
        min-width: 30rem;
      }
       @media only screen and (max-width: 750px) {
        height: auto;
        min-width: 20rem;
      }
    .cookie-text {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.25;
      margin: 4.7rem 0 6.4rem;
      b {
        font-weight: 800;
        display: block;
        margin-bottom: 1rem;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 1.2rem;
        margin: 0 auto;
      }
      @media only screen and (max-width: 750px) {
        font-size: 1rem;
        line-height: 1.3;
        margin-top: 0;
      }
      a {
        color: #000000;
        text-decoration: underline;
        &:hover {
          filter: brightness(1.1);
        }
      }
    }
    .actions-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media only screen and (max-width: 750px) {
        flex-direction: column;
        justify-content: center;
        padding-right: 0;
      }
      div {
        cursor: pointer;
        padding: 1rem;
        text-align: center;
      }
      .manage, .approve {
        align-items: center;
        display: flex;
        border: 1px solid #477AFD;
        color: white;
        font-size: 1.6rem;
        height: 5rem;
        justify-content: center;
        margin: .5rem;
        max-width: 35rem;
        padding: 1rem 0;
        transition: 0.25s ease-in-out;
        width: 100%;
        &:hover {
          background-color: #3C22E9;
          color: white;
        }
        @media only screen and (max-width: 750px) {
          font-size: 1.2rem;
          width: 100%;
        }
      }
      .approve {
        background-color: #477AFD;
      }
      .manage {
        color: #000000;
      }
    }
  .close {
    align-items: center;
    background: #E3E3E3;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    margin: 0 1rem;
    padding: .5rem 0;
    position: absolute;
    right: 0;
    width: 7%;
    &:hover {
      filter: brightness(1.2);
    }
    @media only screen and (max-width: 750px) {
      top: 8px;
      font-size: 1rem;
    }
    span {
      background: #2D2D2D;
      padding: 0 0.5rem;
      p {
        position: relative;
        top: -2px;
      }
    }
  }
}
`;