import React, { Component } from 'react';
import {ComponentWrapper} from './CookiePolicy.css';
import { useLocation } from "@reach/router";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import ManagePopUpBanner from './ManagePopup/ManagePopup';
import { readCookie, createCookie } from '../helpers/helperFunctions';
import withContext from '../../../store/withContext';

class CookiePolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookiePopup: null,
      managePopUp: false,
      hideCookiePrompt: false,
      location: '/'
    };
  }

  checkPageLoad = () => {
    if (readCookie('gatsby-gdpr-google-analytics') !== 'false') {
      createCookie('gatsby-gdpr-google-analytics', true, { path: '/', expires: new Date('2030, 1, 1') });
    }
    window.dataLayer = window.dataLayer || [];
    if (readCookie('gatsby-gdpr-google-tagmanager')) {
      this.setState({
        showCookiePopup: false
      }) 
    } else {
      this.setState({
        showCookiePopup: true
      })
    }
    console.log(readCookie('allowAdvertising'))
    if (readCookie('allowAdvertising') === 'true') {
      window.dataLayer.push({
        'event': 'allowAdvertising'
      });
    }
  }

  loadGSScript = () => {
    if (typeof window !== 'undefined') {
      window.thirez = window.thirez || function () {
        (window.gsght.q = window.thirez.q || []).push(arguments)
      };
      window.thirez.q = [];
      window.thirez.h = 1 * new Date();

      let h = document.createElement('script')
      h.async = 1;
      h.src = 'https://cdn.t.hirez.net/sdk.js';
      document.head.appendChild(h);
    }
  }

  manageBanner = () => {
    this.setState({
      managePopUp: true,
      hideCookiePrompt: true,
      close: false
    })
  }

  hideBannerSetCookie = () => {
    window.dataLayer = window.dataLayer || [];
    this.setState({
      showCookiePopup: false
    })
    createCookie('gatsby-gdpr-google-tagmanager', true, { path: '/', expires: new Date('2030, 1, 1') });
    createCookie('gatsby-gdpr-google-analytics', true, { path: '/', expires: new Date('2030, 1, 1') });
    createCookie('allowAdvertising', true, { path: '/', expires: new Date('2030, 1, 1') });
    //Facebook / Reddit / Snapchat / Google Ads / Twitter
    window.dataLayer.push({
      'event': 'allowAdvertising'
    });
    initializeAndTrack(useLocation);
  }

  hideBanner = () => {
    this.setState({
      showCookiePopup: false,
      managePopUp: false
    })
  }

  hideManagePopup = () => {
    this.setState({
      managePopUp: false
    })
  }

  closeBtn = () => {
    this.setState({
      showCookiePopup: false
    })
  }

  componentDidMount() {
    this.checkPageLoad();
    this.loadGSScript();
  };

  render() {
    return (
      <ComponentWrapper className={`${this.state.showCookiePopup ? 'is-visible' : 'is-hidden'}`}>
        <div role="alert" onClick={this.hideManagePopup} onKeyDown={this.hideManagePopup} className={`hide-manage-popup ${this.state.managePopUp ? 'opacity' : ''}`} />
        <div className={`wrapper ${this.state.hideCookiePrompt ? 'hide' : '' }`}>
          <div onClick={this.closeBtn} className="close">✕</div>
          <div className="cookie-text"><b>This Website Uses Cookies.</b> We use a variety of cookies and other tracking technology to improve our website experience, analyze visitors, and for advertising purposes. Review our <a target="_blank" href="https://www.hirezstudios.com/cookiepolicy">Cookie Policy</a> for more information. </div>
          <div className="actions-wrapper">
            <div className='manage' role='button' tabIndex={0} onClick={this.manageBanner} onKeyDown={this.manageBanner}>Manage Cookies</div>
            <div className='approve' role='button' tabIndex={0} onClick={this.hideBannerSetCookie} onKeyDown={this.hideBannerSetCookie}>Accept All Cookies</div>
          </div>
        </div>
        {this.state.managePopUp === true &&
          <ManagePopUpBanner hide={this.hideBanner} />
        }
      </ComponentWrapper>
    );
  }
}

export default withContext(CookiePolicy);


