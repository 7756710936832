import React, { Component } from 'react';
import { ComponentWrapper } from './Dropdown.css';
import withContext from '../../../../store/withContext';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      analytics: false,
      advertising: false
    };
  }

  toggleInfo = () => {
    if (this.state.open === false) {
      this.setState({
        open: true
      });
    } else {
      this.setState({
        open: false
      });
    }
  }

  updatePreferences = (title) => {
    let props = this.props;
    let analytics = document.getElementById('analytics').checked;
    let advertising = document.getElementById('advertising').checked;
    if (title === 'analytics') {
      if (analytics === true) {
        this.setState({
          analytics: true
        });
        props.setResponse('analytics', true);
      } else {
        this.setState({
          analytics: false
        });
        props.setResponse('analytics', false);
      }
    }


    if (title === 'advertising') {
      if (advertising === true) {
        this.setState({
          advertising: true
        });
        props.setResponse('advertising', true);

      } else {
        this.setState({
          advertising: false
        });
        props.setResponse('advertising', false);

      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (document.getElementById("analytics")) {
        document.getElementById("analytics").click();
      }
    }, 0);
  };
    

  render() {
    const { title, info, location } = this.props;
    return (
      <ComponentWrapper className={this.state.open ? 'show' : ''}>
        <div role="button" tabIndex={0} className="title-wrapper" onClick={this.toggleInfo} onKeyDown={this.toggleInfo}>
          <div className="flair">{this.state.open ? '—' : '+'}</div>
          <h4>
            {title}
          </h4>
          {location === 1 &&
            <p>Always Active</p>
          }
          {location === 2 &&
            <label className="switch">
              <input onClick={(e) => this.updatePreferences('analytics')} id="analytics" type="checkbox" />
              <span className="slider round" />
            </label>
          }
          {location === 3 &&
            <label className="switch">
              <input onClick={(e) => this.updatePreferences('advertising')} id="advertising" type="checkbox" />
              <span className="slider round" />
            </label>
          }
        </div>
        <p className={`info ${this.state.open ? 'show' : ''}`}>info</p>
      </ComponentWrapper>
    )
  }
}

export default withContext(Dropdown);


