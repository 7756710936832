import React, { Component } from 'react';
import { ComponentWrapper } from './ManagePopup.css';
import { useLocation } from "@reach/router";
import Dropdown from './Dropdown';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import withContext from '../../../../store/withContext';
import { createCookie } from '../../helpers/helperFunctions';

class ManagePopUpBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false,
      analytics: false,
      advertising: false
    };
  }

  
  confirmSettings = (a, b) => {
    if (a === 'advertising') {
      if (b === true) {
        this.setState({
          advertising: true
        })
      } else {
        this.setState({
          advertising: false
        })
      }
    } else {
      if (b === true) {
        this.setState({
          analytics: true
        })
      } else {
        this.setState({
          analytics: false
        })
      }
    }
  }

  closeBtn = () => {
    this.setState({
      close: true
    })
    this.props.hide(true);
  }

  setCookies = () => {
    window.dataLayer = window.dataLayer || [];
    this.setState({
      close: true
    })
    this.props.hide(true);

    if (this.state.analytics === true) {
      initializeAndTrack(useLocation);
      createCookie('gatsby-gdpr-google-analytics', true, { path: '/', expires: new Date('2030, 1, 1') });
      createCookie('gatsby-gdpr-google-tagmanager', true, { path: '/', expires: new Date('2030, 1, 1') });
    } else {
      createCookie('gatsby-gdpr-google-analytics', false, { path: '/', expires: new Date('2030, 1, 1') });
      createCookie('gatsby-gdpr-google-tagmanager', false, { path: '/', expires: new Date('2030, 1, 1') });
    }

    if (this.state.advertising === true) {
      createCookie('allowAdvertising', true, { path: '/', expires: new Date('2030, 1, 1') });
      window.dataLayer.push({
        'event': 'allowAdvertising'
      });
    } else {
      createCookie('allowAdvertising', false, { path: '/', expires: new Date('2030, 1, 1') });
      window.dataLayer.push({
        'event': 'doNotTrackAdvertising'
      });
    }
  }

  render() {
    return (
      <ComponentWrapper className={this.state.close ? 'hide' : ''}>
        <div role="button" tabIndex={0} onClick={this.closeBtn} onKeyDown={this.closeBtn} className="close-btn">✕</div>
        <p className="cookie-text"><b>This Website Uses Cookies.</b> We use a variety of cookies and other tracking technology to improve our website experience, analyze visitors, and for advertising purposes. Review our <a target="_blank" href="https://www.hirezstudios.com/cookiepolicy">Cookie Policy</a> for more information.</p>
        <h4>Manage Cookies</h4>
        <Dropdown
          setResponse={this.confirmSettings}
          location={1}
          title={'Necessary/Functionality'}
          info={'Strictly necessary cookies are necessary for the website to work and to enable you to move around it and to use its features. These cookies also help us remember your choices and preferences. Without these cookies, websites will not perform as intended and we may not be able to provide our website or certain services or features you request.'} />
        <Dropdown
          setResponse={this.confirmSettings}
          location={2}
          title={"Analytics"}
          info={"Analytics cookies collect information about your use of the websites, and enable us to improve the way they work."} />
        <Dropdown
          setResponse={this.confirmSettings}
          location={3}
          title={"Advertising"}
          info={"Advertising cookies help us serve relevant ads to you more effectively. They also help us provide aggregated auditing, research, and reporting for advertisers, understand and improve our service, and know when content has been shown to you. We may use third party advertising companies to serve ads when you visit our website."} />
        <button onClick={this.setCookies}>Confirm My Settings</button>
      </ComponentWrapper>
    );
  }
}

export default withContext(ManagePopUpBanner);


